import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"
import urljoin from "url-join";
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { TwitterIcon, FacebookIcon, WhatsappIcon } from 'react-share';

export class SocialShare extends Component {
	render() {

		const title = this.props.title;

		return (
			<div className={`social-share ` + this.props.ssclass}>
				<StaticQuery
					query={graphql`
					query {
						site{
						  siteMetadata{
							liveurl
						  }
						}
					  }
					`}
					render={data => {
						const url = urljoin(data.site.siteMetadata.liveurl, this.props.slug);
						// console.log(url);
						return(
							<Fragment>
								<div className="share-widget">
									<span>Compartir en:</span>
									<ul>
										<li>
											<TwitterShareButton url={url} title={title} hashtags={['barcelonaeventos']}>
												<TwitterIcon size={30} />
											</TwitterShareButton>
										</li>
										<li>
											<FacebookShareButton url={url} quote={title} hashtags={['barcelonaeventos']}>
												<FacebookIcon size={30} />
											</FacebookShareButton>
										</li>
										<li>
											<WhatsappShareButton url={url} title={title}>
												<WhatsappIcon size={30} />
											</WhatsappShareButton>
										</li>
									</ul>
								</div>
							</Fragment>
						)
					}}
				/>
			</div>
		)
	}
}

export default SocialShare
