import React from "react"
import { Link, graphql } from "gatsby"
import { SimpleImg } from 'react-simple-img';
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BlogBio from '../components/BlogBio'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShare from "../components/SocialShare"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
		const { title, description, file } = node.data.target.fields;
		const mimeType = file['en-US'].contentType
		const mimeGroup = mimeType.split('/')[0]

		switch (mimeGroup) {
		  case 'image':
			return <img
			  className="img-fluid mb-4"
			  title={ title ? title['en-US'] : null}
			  alt={description ?  description['en-US'] : null}
			  src={file['en-US'].url}
			/>
		  case 'application':
			return <a
			  alt={description ?  description['en-US'] : null}
			  href={file['en-US'].url}
			  >{ title ? title['en-US'] : file['en-US'].details.fileName }
			</a>
		  default:
			return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset </span>
		}
	  }
  },
}

class BlogPostContentfulTemplate extends React.Component {
	render() {
		const post = this.props.data.contentfulPost
		const siteTitle = this.props.data.site.siteMetadata.title
		const { previous, next } = this.props.pageContext
		// console.log(post)

		return (
			<Layout location={this.props.location} title={siteTitle}>

				<SEO title={post.titulo} description={post.subtitulo} />

				<section className="blog-post">
					<div className="poster">
						<SimpleImg srcSet={post.imagen.fluid.srcSet} placeholder={false}/>
					</div>

					<div className="container">
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-10 col-lg-9">
								<article>

									<header>
										<h1>{post.titulo}</h1>
									</header>

									{ documentToReactComponents(post.contenido.json, options) }

									<hr/>

									<BlogBio/>

									<hr/>

									<ul className="post-navigation">
										<li>
											{previous && (
												<Link to={`/noticias/${previous.slug}`} rel="prev">
													<span>←</span> {previous.titulo}
												</Link>
											)}
										</li>
										<li>
											{next && (
												<Link to={`/noticias/${next.slug}`} rel="next">
													{next.titulo} <span>→</span>
												</Link>
											)}
										</li>
									</ul>

									<hr/>

									<SocialShare title={post.titulo} slug={`/noticias/${post.slug}`}/>

								</article>
							</div>
						</div>

					</div>
				</section>

			</Layout>
		)
	}
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost( slug: { eq: $slug } ){
	  slug
      titulo
      subtitulo
      contenido {
		  json
        childContentfulRichText{
          html
        }
      }
      imagen{
        fluid{
          srcSet
        }
      }
    }
  }
`
