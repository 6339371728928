import React, {Component} from "react"
import { Link } from "gatsby"
import { SimpleImg } from 'react-simple-img'

import logo from '../images/barcelona-eventos.png'

class BannerAgenda extends Component{
	render(){
		return(
			<div className="blog-bio media">
				<SimpleImg src={logo} alt="Barcelona Eventos" placeholder={false} width="90" height="51" className="mr-3" />
				<div className="media-body">
					<p className="mb-0">Bienvenido a Barcelona Eventos, hogar de uno de los más hermosos salones de eventos en Apodaca, Nuevo León. Además ofrecemos un caterging absolutamente perfecto para tu día especial. <Link to="/eventos">Ver nuestros servicios</Link></p>
				</div>
			</div>
		)
	}
}

export default BannerAgenda
